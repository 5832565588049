import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { Settings } from './Settings';
import { Updater } from './Updater';

import * as cmd from './Commands';
import { firmwareSourceForHardware } from './FirmwareRepository';
import { AbsType, IdentResponse } from './generated/wss_pb';
import { StatusView } from './StatusView';
import { isAck } from './ProtobufCommand';
import { TestMode } from './TestMode';
import { Diagnostic } from './Diagnostic';

const NoSerial = (): JSX.Element => (<div className='uiSection warning'>This browser is not supported, as it isn't able to communicate with your Mk60 Companion. Please use a recent version of Google Chrome or Microsoft Edge instead.</div>);

const InfoUi = (): JSX.Element => (
    <div className='uiSection'>
        <div><a href="https://happycactusgarage.com/">Happy Cactus Garage Store</a></div>
        <div><a href="https://github.com/mck1117/abs-docs/blob/main/Mk60-Companion/Setup-Guide.md">MK60 Companion Instructions</a></div>
        <div><a href="https://discord.gg/hQUz5Vgk9Z">Discord Community</a></div>
    </div>);

enum ConnectionState {
    Disconnected = 'Disconnected',
    ConnectedBootloader = 'Connected to Bootloader',
    ConncetedFirmware = 'Connected to Firmware',
};

export const App = (): JSX.Element => {
    const supportsSerial = useMemo((): boolean => {
        return "serial" in navigator;
    }, []);

    const isMfgMode = window.location.search ? (window.location.search.indexOf('mfgmode')) > 0 : false;

    const [serialPort, setSerialPort] = useState<SerialPort | undefined>();
    const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.Disconnected);
    const [controllerIdent, setControllerIdent] = useState<IdentResponse>();

    const availableFirmware = firmwareSourceForHardware(controllerIdent?.getHardwarerevision() ?? 0);
    const updateIsAvailable = 
                        availableFirmware &&
                        controllerIdent &&
                        !controllerIdent.getIsbootloader() &&
                        (availableFirmware.swVersion > controllerIdent.getVersion());

    // True if connected with a valid serial port and controller ident
    const isConnected = !!(connectionState !== ConnectionState.Disconnected && serialPort && controllerIdent);

    // Request that the user select a serial port
    const findPort = useCallback(async () => {
        if (supportsSerial) {
            try {
                const port = await navigator.serial.requestPort();
                await port.open({ baudRate: 115200 });
                setSerialPort(port);
            } catch {
                // swallow error, user canceled or something
            }
        }
    }, [supportsSerial, setSerialPort]);

    // Disconnect/cleanup handler
    const onDisconnect = useCallback(async (autoReconnect: boolean) => {
        if (serialPort) {
            const port = serialPort;
            setSerialPort(undefined);
            setConnectionState(ConnectionState.Disconnected);

            await port.close();
            await port.forget();

            if (autoReconnect) {
                findPort();
            }
        }
    }, [serialPort, findPort]);

    // When we get a new serial port, probe whether it's openblt or firmware
    useEffect(() => {
        if (serialPort) {
            (async () => {
                const resp = await cmd.identify(serialPort, onDisconnect);

                if (resp?.hasIdent()) {
                    const ident = resp?.getIdent()!;
                    setControllerIdent(ident);

                    if (ident.getIsbootloader()) {
                        setConnectionState(ConnectionState.ConnectedBootloader);
                    } else {
                        setConnectionState(ConnectionState.ConncetedFirmware);
                    }
                } else {
                    // TODO: failed to connect
                    onDisconnect(false);
                }
            })();
        }
    }, [serialPort, setControllerIdent, setConnectionState, onDisconnect]);

    const onRebootToLoader = useCallback(async () => {
        const response = await cmd.rebootToBootloader(serialPort!, onDisconnect);
        if (isAck(response)) {
            // only do disconnect if ack - otherwise stay in fw update
            onDisconnect(true);
        }
    }, [serialPort, onDisconnect]);

    // UI for connect/disconnect
    const ConnectionUi = (): JSX.Element => (
        <div className='uiSection'>
            <h2>Connection</h2>
            {isConnected ?
                (<>
                    <button type='button' onClick={() => onDisconnect(false)}>Disconnect</button>
                    {controllerIdent.getIsbootloader() ?
                        (<button type='button' onClick={onRebootToLoader}>Reboot to app</button>) :
                        (<button type='button' onClick={onRebootToLoader}>Reboot to bootloader</button>)}
                </>) : (<button type='button' onClick={findPort}>Connect</button>)}
            <p />
            <div>State: {connectionState}</div>
            {controllerIdent && (<div>Software: {controllerIdent.getVersion()}</div>)}
            {updateIsAvailable && (<div className='warning'>Software update available: {availableFirmware.swVersion}</div>)}
            {controllerIdent && (<div>Hardware ID: {controllerIdent.getHardwarerevision()}</div>)}
            {controllerIdent && !availableFirmware && (<div className='warning'>Hardware ID not recognized, please contact support.</div>)}
            {controllerIdent && (<div>SN: {Buffer.from(controllerIdent.getSerialnumber()).toString('hex')}</div>)}
        </div>
    );

    const absTypeRef = useRef<AbsType>(AbsType.GENERICONOFF);

    const MainUi = (): JSX.Element => (
        <div className='uiSection'>
            <div>
                {
                    connectionState === ConnectionState.ConncetedFirmware
                        ? (<Settings port={serialPort!} onDisconnect={onDisconnect} absTypeRef={absTypeRef} isMfgMode={isMfgMode} />)
                        : availableFirmware
                            ? (<Updater port={serialPort!} firmwareLocation={availableFirmware.file} controllerIdent={controllerIdent!} onDisconnect={onDisconnect} />)
                            : (<>
                                <h2>Firmware Update Error</h2>
                                <div className="warning">No firmware update is available for your device with hardware ID {controllerIdent?.getHardwarerevision()}. Please contact support.</div>
                            </>)
                }
            </div>
        </div>
    );

    // UI for live status
    const StatusUi = (): JSX.Element => (
        <>
            {connectionState === ConnectionState.ConncetedFirmware && (
                <div className='uiSection'>
                    <StatusView port={serialPort!} onDisconnect={onDisconnect} absTypeRef={absTypeRef} isMfgMode={isMfgMode} />
                </div>)}
        </>);

    // UI for setting test mode parameters
    const TestModeUi = (): JSX.Element => (
        <>
            {connectionState === ConnectionState.ConncetedFirmware && (
                <div className='uiSection'>
                    <TestMode port={serialPort!} onDisconnect={onDisconnect} />
                </div>)}
        </>);

    const DiagnosticUi = (): JSX.Element => (
        <>
            {connectionState === ConnectionState.ConncetedFirmware && (
                <div className='uiSection'>
                    <Diagnostic port={serialPort!} onDisconnect={onDisconnect} />
                </div>)}
        </>);

    return (
        <div className='uiRoot'>
            <div className='uiSection'>
                <img alt='Happy Cactus Garage' src='/HappyCactusGarage_header.png' style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '100%' }} />
                <h1 style={{ marginTop: '12px' }}>MK60 Companion Tool</h1>
            </div>
            <InfoUi />
            {!supportsSerial && (<NoSerial />)}
            {supportsSerial &&
                (<>
                    <ConnectionUi />
                    {isConnected && <StatusUi />}
                    {isConnected && <MainUi />}
                    {isConnected && <TestModeUi /> }
                    {isConnected && <DiagnosticUi /> }
                </>)
            }
        </div>);
}

export default App;
