import * as cmd from "./Commands";
import { useState } from "react";

const codeMeanings = new Map<number, string>([
    [0x5D8F, 'Sensor cluster Fehler intern'],
    [0x5D97, 'Sensor cluster supply voltage outside valid range'],
    [0x5D9A, 'Pressure sensor front left electrical defect'],
    [0x5D9B, 'Pressure sensor front left plausibility'],
    [0x5D9C, 'Pressure sensor front left front axle plausibility'],
    [0x5D9D, 'Pressure sensor front left hydraulic leakage front axle'],
    [0x5D9E, 'Pressure sensor front left p1 gradient error'],
    [0x5D9F, 'Pressure sensor front left plausibility fine tuning error'],
    [0x5DA7, 'Hydraulic leakage front axle or rear axle'],
    [0x5DAA, 'Pressure sensor front right electrical defect'],
    [0x5DAB, 'Pressure sensor front right Plausibility'],
    [0x5DAC, 'Pressure sensor front right front axle Plausibility'],
    [0x5DAD, 'Pressure sensor front right hydraulic leakage front axle'],
    [0x5DAE, 'Pressure sensor front right p1 gradient error'],
    [0x5DAF, 'Pressure sensor front right plausibility fine tuning error'],
    [0x5DBA, 'Pressure sensor rear left electrical defect'],
    [0x5DBB, 'Pressure sensor rear left Plausibility'],
    [0x5DBC, 'Pressure sensor rear left rear axle Plausibility'],
    [0x5DBD, 'Pressure sensor rear left hydraulic leakage rear axle'],
    [0x5DBE, 'Pressure sensor rear left p1 gradient error'],
    [0x5DBF, 'Pressure sensor rear left plausibility fine tuning error'],
    [0x5DCA, 'Pressure sensor rear right electrical defect'],
    [0x5DCB, 'Pressure sensor rear right Plausibility'],
    [0x5DCC, 'Pressure sensor rear right rear axle Plausibility'],
    [0x5DCD, 'Pressure sensor rear right hydraulic leakage rear axle'],
    [0x5DCE, 'Pressure sensor rear right p1 gradient error'],
    [0x5DCF, 'Pressure sensor rear right plausibility fine tuning error'],
    [0x5DD0, 'Valve calibration data missing'],
    [0x5DD1, 'RPA EEPROM checksum error'],
    [0x5DD2, 'Inlet valve calibration data missing'],
    [0x5DD3, 'ECU intern: ECU-Betriebssystem (OSEC) erkennt ECU-internen Fehler'],
    [0x5D90, 'Wheel speed sensor front left electrical defect'],
    [0x5D91, 'Wheel speed sensor front left extrapolation'],
    [0x5D92, 'Wheel speed sensor pulse front left periodic monitoring'],
    [0x5D93, 'Wheel speed sensor front left Anfahrerkennung v_Vergleich'],
    [0x5D94, 'Wheel speed sensor front left long-term monitoring'],
    [0x5D95, 'Wheel speed sensor front left check for double pulse wheel frequency'],
    [0x5D96, 'Rotation direction front left'],
    [0x5DA0, 'Wheel speed sensor front right electrical defect'],
    [0x5DA1, 'Wheel speed sensor front right extrapolation'],
    [0x5DA2, 'Wheel speed sensor pulse front right periodic monitoring'],
    [0x5DA3, 'Wheel speed sensor front right Anfahrerkennung v_Vergleich'],
    [0x5DA4, 'Wheel speed sensor front right long-term monitoring'],
    [0x5DA5, 'Wheel speed sensor front right check for double pulse wheel frequency'],
    [0x5DA6, 'Rotation direction front right'],
    [0x5DB0, 'Wheel speed sensor rear left electrical defect'],
    [0x5DB1, 'Wheel speed sensor rear left extrapolation'],
    [0x5DB2, 'Wheel speed sensor pulse rear left periodic monitoring'],
    [0x5DB3, 'Wheel speed sensor rear left Anfahrerkennung v_Vergleich'],
    [0x5DB4, 'Wheel speed sensor rear left long-term monitoring'],
    [0x5DB5, 'Wheel speed sensor rear left check for double pulse wheel frequency'],
    [0x5DB6, 'Rotation direction rear left'],
    [0x5DC0, 'Wheel speed sensor rear right electrical defect'],
    [0x5DC1, 'Wheel speed sensor rear right extrapolation'],
    [0x5DC2, 'Wheel speed sensor pulse rear right periodic monitoring'],
    [0x5DC3, 'Wheel speed sensor rear right Anfahrerkennung v_Vergleich'],
    [0x5DC4, 'Wheel speed sensor rear right long-term monitoring'],
    [0x5DC5, 'Wheel speed sensor rear right check for double pulse wheel frequency'],
    [0x5DC6, 'Rotation direction rear right'],
    [0x5DF0, 'Pump motor defective'],
    [0x5DF1, 'Pump motor connector defective'],
    [0x5DF2, 'Valve/ECU hardware error, ROM/RAM check error'],
    [0x5DF4, 'Vehicle electrical system < 9 volt'],
    [0x5DF5, 'Control unit internal error'],
    [0x5DF7, 'Vehicle electrical system > 18 volt'],
    [0x5DFF, 'Pump motor max speed exceeded'],
    [0x5E18, 'CAN DME/DDE Botschaft unplausibel'],
    [0x5E19, 'CAN DME/DDE, Motormoment nicht einstellbar'],
    [0x5E1A, 'CAN DME/DDE Signal Fehler'],
    [0x5E1B, 'CAN DME/DDE Motormoment Signal Fehler'],
    [0x5E1C, 'PT-CAN HSA Signal Fehler'],
    [0x5E1D, 'PT-CAN chassis number invalid'],
    [0x5E1F, 'PT-CAN chassis number incorrect / ECU not initialized'],
    [0x5E3F, 'F-CAN SZL Coding error'],
    [0xD347, 'PT-CAN-Bus Off'],
    [0xD34B, 'F-CAN-Bus Off'],
    [0xD354, 'PT-CAN DME/DDE_1 - Torque 1 message 168 missing'],
    [0xD355, 'PT-CAN DME/DDE_2 - Torque 2 message 169 missing'],
    [0xD356, 'PT-CAN DME/DDE_3 - Torque 3 message 170 missing'],
    [0xD357, 'PT-CAN Getriebe 1 - Transmission data message 186 missing'],
    [0xD358, 'PT-CAN Instrumentcluster_1 - outside temperature/relative time message 784 missing'],
    [0xD359, 'PT-CAN Instrumentcluster_2 - Mileage message 816 missing'],
    [0xD35A, 'PT-CAN Gateway_1 - Terminal status message 304 missing'],
    [0xD35B, 'PT-CAN LDM-Fehler - message 213: Wheel torque brake requirement, ANF_RADMOM_BREMSE (ID 0xD5)'],
    [0xD35C, 'PT-CAN ACC_2'],
    [0xD35D, 'PT-CAN ACC Langzeit Timeout message 419 missing'],
    [0xD360, 'PT-CAN Motronic_4 Plausibility'],
    [0xD361, 'PT-CAN Chassis number timeout, message 896 missing'],
    [0xD362, 'PT-CAN Motronic_5'],
    [0xD363, 'PT-CAN Instrument_cluster_3'],
    [0xD364, 'PT-CAN Operator_Key_Tempo'],
    [0xD370, 'F-CAN Sensor cluster_1 message 205 missing'],
    [0xD371, 'F-CAN Sensor cluster_2 message 209 missing'],
    [0xD372, 'F-CAN Sensor cluster_3 message 212 missing'],
    [0xD373, 'F-CAN SWA-Top-Sensor message 200 oder 201 missing'],
    [0xD374, 'F-CAN SWA-Raw-Sensor message 195 missing'],
    [0xD375, 'F-CAN AFS - Austausch AFS-DSC message 118 missing'],
    [0xD376, 'F-CAN AFS message 11F missing'],
    [0xD377, 'PT_CAN_Timeout message 740 (Status_Anhaenger) missing'],
    [0xD378, 'PT_CAN_Timeout message 944  (Status_Gang_Rueckwaerts) missing'],
    [0x5E50, 'F-CAN SZL Serial number incorrect'],
    [0x5E51, 'F-CAN SZL Serial number invalid'],
    [0x5E52, 'AFS Aktuator Signal unplausibel'],
    [0x5E53, 'GMK gesperrt durch AFS'],
    [0x5E54, 'GMK gesperrt wegen dauernder GMK-Regelung'],
    [0xC987, 'F-CAN SZL keine Botschaften Bus-Off'],
    [0xC994, 'F-CAN SZL Radgeschwindigkeit, Kommunikation mit DSC, Timeout (Nachricht in applizierbarer Zeit nicht empfangen)'],
    [0xC995, 'F-CAN SZL Radgeschwindigkeit Plausibility, Kommunikation mit DSC, Radgeschwindigkeit >300km/h'],
    [0xC996, 'F-CAN SZL Radgeschwindigkeit Plausibility, Kommunikation mit DSC, Radgeschwindigkeit <300km/h'],
    [0xC997, 'F-CAN SZL Radtoleranzabgleich, Kommunikation mit DSC, Timeout (Nachricht in applizierbarer Zeit nicht empfangen)'],
    [0xC998, 'F-CAN SZL Radtoleranzabgleich Plausibility, Kommunikation mit DSC, Radtoleranzabgleich < -5%'],
    [0xC99A, 'F-CAN SZL Radtoleranzabgleich Plausibility, Kommunikation mit DSC, Radtoleranzabgleich > 5%'],
    [0xC99B, 'F-CAN SZL Sync, keine Kommunikation mit DSC, Timeout'],
    [0xC99C, 'F-CAN SZL, Klemmenstatus, keine Kommunikation mit DSC, Timeout'],
    [0x5E20, 'Pressure sensor 1 electrical defect'],
    [0x5E24, 'Pressure sensor 1 unplausibel'],
    [0x5E25, 'Pressure sensor 1 unplausibel Feinabstimmungsfehler'],
    [0x5E26, 'Power supply sensors'],
    [0x5E28, 'LDM-Fehler: Fahrpedal ueberwachung, LDM-Bremsanforderung gegenueber Fahrpedal unplausibel'],
    [0x5E29, 'LDM-Fehler: Freigabe ueberwachung, LDM-Bremsanforderung ohne Freigabe'],
    [0x5E2A, 'Overbraking detection by DCC braking process'],
    [0x5E2B, 'Underbraking detection by DCC braking process'],
    [0x5E2C, 'hydraulic leakage front axle'],
    [0x5E2D, 'DSC Differenzdruckueberwachung VA/HA zulaessiger Differenzdruck ueberschritten'],
    [0x5E2E, 'Schalter Rueckwaertsgang Plausibility'],
    [0x5E2F, 'Temperature sensor'],
    [0x5E30, 'Lateral acceleration sensor signal outside valid range or electrical defect'],
    [0x5E31, 'Lateral acceleration sensor offset error'],
    [0x5E32, 'Lateral acceleration sensor implausible'],
    [0x5E33, 'Lateral acceleration sensor offset error'],
    [0x5E34, 'Longitudinal acceleration sensor signal outside valid range or electrical defect'],
    [0x5E35, 'Longitudinal acceleration sensor implausible'],
    [0x5E36, 'Longitudinal acceleration sensor offset error'],
    [0x5E37, 'Longitudinal acceleration sensor self-test error'],
    [0x5E38, 'Yaw rate sensor Signal signal outside valid range or electrical defect'],
    [0x5E39, 'Yaw rate sensor offset error'],
    [0x5E3A, 'Yaw rate sensor offset error standstill'],
    [0x5E3B, 'Yaw rate sensor offset error CBit'],
    [0x5E3C, 'Yaw rate sensor unplausibel'],
    [0x5E3D, 'Lateral acceleration sensor self-test error'],
    [0x5E40, 'Lenkwinkelsensor Signal unplausibel,Offset'],
    [0x5E43, 'Lenkwinkelsensor intern'],
    [0x5E46, 'Lenkwinkelsensor Signal, Offset Fehler'],
    [0x5E47, 'Lenkwinkelsensor Fehler Klemmen Status'],
    [0x5E48, 'Lenkwinkelsensor Signal Fehler Toleranz Check'],
    [0x5E49, 'Lenkwinkelsensor Initialisierungs Fehler'],
    [0x5E4E, 'DSC Sensor Offset Check'],
    [0x5E4F, 'DSC Dauerregelung'],
    [0x5E58, 'ASC ECU im falschen Fahrzeug'],
    [0x5E59, 'Codierfehler'],
    [0x5E5B, 'DSC Taster laenger als 10sec gedrueckt oder Fehler'],
    [0x5E5C, 'RPA Taster Fehler'],
    [0x5E5D, 'Brake fluid level low / switch defective'],
    [0x5E5E, 'Brake light switch error, plausibility error'],
    [0x5E60, 'PT-CAN Engine4 Signal Fehler'],
    [0x5E61, 'PT-CAN Engine5 Signal Fehler'],
    [0x5E62, 'PT-CAN Gear1 Signal Fehler'],
    [0x5E63, 'PT-CAN Instr_Cluster3 Signal Fehler'],
    [0x5E64, 'PT-CAN Instr_Cluster3 Plausibilitys Fehler_1'],
    [0x5E65, 'PT-CAN Instr_Cluster3 Plausibilitys Fehler_2'],
    [0x5E66, 'F-CAN Engine4 Signal Fehler'],
    [0x5E67, 'PT-CAN DCC Signal Fehler_1'],
    [0x5E68, 'PT-CAN DCC Signal Fehler_2'],
    [0x5DE0, 'Brake pad wear VA not/incorrectly initialized'],
    [0x5DE1, 'Brake pad wear HA not/incorrectly initialized'],
    [0x5DE2, 'Brake pad wear VA critical coating thickness reached'],
    [0x5DE3, 'Brake pad wear HA critical coating thickness reached'],
    [0x94B0, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Synchronisation mit Sub nicht moeglich'],
    [0x94B1, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Verbindungstest zur PDA fehlgeschlagen'],
    [0x94B2, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Umgebungshelligkeit zu hoch'],
    [0x94B3, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - keine Referenzspur gefunden'],
    [0x94B4, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Referenzspurabstand ausserhalb des Toleranzbandes'],
    [0x94B5, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Illegaler Code'],
    [0x94B6, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Winkelsprung zu gross'],
    [0x94B7, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Lenkwinkel-Messbereich ueberschritten (Rundenoverflow)'],
    [0x94B8, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Winkelverifizierung durch Main und Sub fehlerhaft'],
    [0x94B9, 'F-CAN SZL Lenkwinkelsensor : Sensor nicht abgeglichen - EEPROM defekt (nach Anklemmen der KL30)'],
    [0x94BA, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_BelZeit_0 Interner Softwarefehler'],
    [0x94BB, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_VglAbwurf Fehler im Sub Lenkwinkel'],
    [0x94BC, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_CRCFehlInCDB Fehler in EEPROM-Daten'],
    [0x94BD, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - Info: Unterspannung, LWS_CRCRamSave Fehler im RAM-Bereich'],
    [0x94BE, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_Winkelpraediktion Codescheibe defekt'],
    [0x94BF, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_TimeoutSubCom Verbindung zum SUB defekt'],
    [0x94C0, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_BelTimeout Klemme 30 Startzeit zu lang'],
    [0x94C1, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_TimeoutSub Kommunikationsfehler Sub'],
    [0x94C2, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_KeinAbgleich Nullpunktdaten fehlerhaft'],
    [0x94C3, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_ERROR allgemeiner Sensorfehler'],
    [0x94C4, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_REF_NOTFOUND keine Referenzspur gefunden'],
    [0x94C5, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_CRCSaveRamCopy Fehler im RAM'],
    [0x94C7, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_CRCLwsNullShw Nullpunktdaten CRC Fehler'],
    [0x94C8, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_CRCLwsShw Konfigurationsdaten CRC Fehler'],
    [0x94C9, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS_TimeoutEEPROM EEPROM Fehler'],
    [0x94E0, 'F-CAN SZL EEPROM defekt - Prozessor defekt'],
    [0x94E1, 'F-CAN SZL EEPROM defekt - Fehler intern Autocodierung'],
    [0x9500, 'F-CAN SZL Unterspannung   UBatt < 8.5 V'],
    [0x9501, 'F-CAN SZL Ueberspannung    UBatt > 16,5 V'],
    [0x9510, 'FGR/ACC-Lenkstockschalter (LST) haengt (alle tastenden Schalter)- mechanisch defekt, Kontakt'],
    [0x9511, 'FGR/ACC-Lenkstockschalter (LST) unplausibel - Unzulaessige Kombination mit Tempomatschalter'],
    [0x9512, 'FGR/ACC-Lenkstockschalter (LST) electrical defect'],
    [0x9518, 'Scheibenwischerschalter (SWS) (alle tastenden Schalter)- mechanisch defekt, Kontakt'],
    [0x9519, 'Scheibenwischerschalter (SWS) unplausibel- Unzulaessige Kombination im Scheibenwischerschalter'],
    [0x951A, 'Scheibenwischerschalter (SWS) -Schalter defekt - electrical defect'],
    [0x9520, 'Lenkrad MFL: mechanisch defekt, Kontakt'],
    [0x5DD4, 'RPA abgeschaltet durch das Luftfeder Steuergeraet'],
    [0x5DEF, 'Info. WLC (Wandler Launch Control) deaktiviert/stillgelegt'],
    [0x5DEC, 'Info. FLR (Fahrleistungsreduzierung) aktiviert'],
    [0x5DF8, 'Unterspannung Kl30'],
    [0x5E44, 'Lenkwinkelsensor hat die Initialisierung verloren'],
    [0x5E45, 'Lenkwinkelsensor Fehler bei der Auswertung des Lenkwinkelsignals im DSC'],
    [0x94C6, 'F-CAN SZL Lenkwinkelsensor : Sensorfehler - LWS Nullpunkt CRC Fehler'],
    [0x9502, 'F-CAN SZL Lenkwinkelsensor Unterspannung erkannt'],
    [0x9503, 'F-CAN SZL Lenkwinkelsensor Ueberspannung erkannt'],
    [0xD365, 'PT-CAN Botschaft Luftfeder Fehler'],
    [0x5E70, 'Info: auf Grund der Fahrweise ergeben sich bei passiv getastetem DSC unplausible Sensorsignale, RPA und andere Zusatzfunktionen werden stillgelegt'],
    [0xD36E, 'PT-CAN   Botschaft 310h A_TEMP_RELATIVZEIT fehlt oder ungueltig, Reifentemperaturmodell im RPA+ kann nicht berechnet werden'],
    [0x5DD7, 'RPA+ kann nicht aufsetzen, waehrend der Initialisierungsphase werden keine oder nicht genuegend Kalibrierwerte gesammelt, Reifen fuer RPA+ nicht geeignet'],
    [0xD379, 'PT_CAN Botschaft 776 Status MSA fehlt'],
    [0x5EFB, 'ELUP/Unterdrucksystem: Leckage Unterdrucksystem'],
    [0x5EFE, 'ELUP/Unterdrucksystem: Unterdruck Sensor defekt'],
    [0x5EFF, 'ELUP/Unterdrucksystem:  elektrische Unterdruckpumpe oder Ansteuerung (AE) defekt'],
    [0xD368, 'ELUP/Unterdrucksystem: PT_CAN Botschaft 8Dh STAT_ELUP ungueltig'],
]);

const makeNettoData = (data: Uint8Array) => {
    let result = '';

    let idx = 0;

    const lineLength = 16;

    while (idx < data.length) {
        result += 'B 003000' + idx.toString(16).toUpperCase().padStart(2, '0') + ',' + lineLength.toString(16).toUpperCase().padStart(4, '0');

        for (let i = 0; i < lineLength; i++) {
            result += ',' + data[idx].toString(16).toUpperCase().padStart(2, '0');

            idx++;
            if (idx === data.length) {
                break;
            }
        }

        result += '\n';
    }

    return result;
};

type Props = Readonly<{
    port: SerialPort;
    onDisconnect: (why: any) => void;
}>;

export const Diagnostic = ({ port, onDisconnect }: Props): JSX.Element => {
    const [dtcText, setDtcText] = useState<string>();

    const onClearDtcs = async() => {
            setDtcText('Clearing DTCs...');

            const result = await cmd.clearDtcs(port, onDisconnect);

            if (result?.hasAck()) {
                setDtcText('DTC clear completed!');
            } else if (result?.hasNak()) {
                const nakMsg = new TextDecoder().decode(result.getNak()!.getErrormessage_asU8());
                setDtcText(`DTC clear failed: ${nakMsg}`);
            }
        };

    const onReadDtcs = async() => {
            setDtcText('Reading DTCs...');

            const result = await cmd.readDtcs(port, onDisconnect);

            if (result?.hasReaddata()) {
                const dtcBytes = result.getReaddata()!.getData_asU8();
                const dtcCount = dtcBytes.length / 3;

                let resultStr = '';
                resultStr += 'DTC count: ' + dtcCount + '\n';

                for (let i = 0; i < dtcCount; i++) {
                    const code =
                          dtcBytes[3 * i] * 256
                        + dtcBytes[3 * i + 1];

                    // const flags = dtcBytes[3 * i + 2];

                    // resultStr += `DTC: ${code.toString(16)} Flags: ${flags.toString(16)}`;
                    resultStr += `DTC: ${code.toString(16).toUpperCase().padStart(4, '0')}`;

                    const decoded = codeMeanings.get(code);
                    if (decoded) {
                        resultStr += `: ${decoded}`;
                    }

                    resultStr += '\n';
                }

                setDtcText(resultStr);
            } else {
                setDtcText('Error reading DTCs.');
            }
        };

    const onReadCoding = async () => {
        setDtcText('Reading coding data...');

        const result = await cmd.readCodingData(port, onDisconnect);

        if (result && result.hasReaddata()) {
            const data = result.getReaddata()?.getData_asU8()!;

            setDtcText(makeNettoData(data));
        }
    };

    const onReadIdent = async () => {
        setDtcText('Reading ident data...');

        const result = await cmd.readIdentData(port, onDisconnect);

        if (result && result.hasReaddata()) {
            const data = result.getReaddata()?.getData_asU8()!;

            let outputString = makeNettoData(data);

            outputString += '\n';

            let bmwPn = data[2].toString(16);

            for (let i = 3; i <= 5; i++) {
                bmwPn += data[i].toString(16).padStart(2, '0');
            }

            outputString += `BMW part number: ${bmwPn}\n`;
            outputString += `Coding index: ${data[7]}`;

            setDtcText(outputString);
        }
    };

    return (
        <>
            <h2>Diagnostics</h2>
            <button type='button' onClick={onReadDtcs}>Read DTCs</button>
            <button type='button' onClick={onClearDtcs}>Clear DTCs</button>
            <button type='button' onClick={onReadCoding}>Read Coding Data</button>
            <button type='button' onClick={onReadIdent}>Read Ident</button>
            {dtcText &&
                <>
                    <p />
                    <pre>{dtcText}</pre>
                </>}
        </>
    );
};
