
// 100 = rev A
// 101 = rev B, C
const firmwares = [
    // { hw: [101], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_240126.hex', swVersion: 24012621 },
    // { hw: [101], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_240701.hex', swVersion: 24070121 },
    // { hw: [101], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_250121.hex', swVersion: 25012206 },
    { hw: [101], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_250121.hex', swVersion: 25012206 },
    { hw: [102], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_250121.hex', swVersion: 25012206 },
    { hw: [103], file: 'https://happycactusgarage.s3.us-east-2.amazonaws.com/wss_main_release_250121.hex', swVersion: 25012206 },
];

// Returns information about the firmware available for the provided hardware ID, or undefined if none is found.
export const firmwareSourceForHardware = (hwVersion: number): { swVersion: number, file: string } | undefined => {
    const result = firmwares.find(e => !!(e.hw.find(e2 => e2 === hwVersion)));

    return result ? { swVersion: result?.swVersion, file: result.file } : undefined;
};
