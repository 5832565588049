import { protobufTransact } from "./ProtobufCommand";
import { Command, DataWithAddr, DiagnosticCommand, OffsetSize, Request, SetTestMode, WssConfig } from "./generated/wss_pb";

export const identify = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const req = new Request();
    req.setIdentify(new Command());

    return protobufTransact(port, req, onDisconnect);
};

export const rebootToBootloader = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const req = new Request();
    req.setRebootloader(new Command());

    return protobufTransact(port, req, onDisconnect);
};

export const doEspPassthrough = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const req = new Request();
    req.setDoesppassthrough(new Command());

    return protobufTransact(port, req, onDisconnect);
};

export const erase = async (port: SerialPort, onDisconnect: (why: any) => void, offset: number, size: number) => {
    const eraseReq = new Request();
    eraseReq.setErase(new OffsetSize());
    eraseReq.getErase()?.setOffset(offset);
    eraseReq.getErase()?.setSize(size);

    return protobufTransact(port, eraseReq, onDisconnect, 2000);
};

export const write = async (port: SerialPort, onDisconnect: (why: any) => void, offset: number, data: Uint8Array) => {
    const writeReq = new Request();
    writeReq.setWrite(new DataWithAddr());
    writeReq.getWrite()?.setData(data);
    writeReq.getWrite()?.setOffset(offset);

    return protobufTransact(port, writeReq, onDisconnect);
};

export const getConfig = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const configRequest = new Request();
    configRequest.setGetconfig(new Command());

    return protobufTransact(port, configRequest, onDisconnect);
}

export const setConfig = async (port: SerialPort, onDisconnect: (why: any) => void, config: WssConfig) => {
    const configRequest = new Request();
    configRequest.setSetconfig(config);

    return protobufTransact(port, configRequest, onDisconnect);
}

export const getStatus = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const configRequest = new Request();
    configRequest.setGetstatus(new Command());

    return protobufTransact(port, configRequest, onDisconnect);
}

export const setTestMode = async (port: SerialPort, onDisconnect: (why: any) => void, testModeParams: SetTestMode) => {
    const configRequest = new Request();
    configRequest.setSettestmode(testModeParams);

    return protobufTransact(port, configRequest, onDisconnect);
}

export const exitTestMode = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const configRequest = new Request();
    configRequest.setExittestmode(new Command());

    return protobufTransact(port, configRequest, onDisconnect);
}

export const runSelfTest = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const configRequest = new Request();
    configRequest.setRunselftest(new Command());

    return protobufTransact(port, configRequest, onDisconnect);
}

export const readDtcs = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const dtcRequest = new Request();
    dtcRequest.setDiagnostic(new DiagnosticCommand().setReaddtc(new Command()));

    return protobufTransact(port, dtcRequest, onDisconnect);
}

export const clearDtcs = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const dtcRequest = new Request();
    dtcRequest.setDiagnostic(new DiagnosticCommand().setCleardtc(new Command()));

    return protobufTransact(port, dtcRequest, onDisconnect);
}

export const readCodingData = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const dtcRequest = new Request();
    dtcRequest.setDiagnostic(new DiagnosticCommand().setReadcodingdata(new Command()));

    return protobufTransact(port, dtcRequest, onDisconnect);
}

export const readIdentData = async (port: SerialPort, onDisconnect: (why: any) => void) => {
    const dtcRequest = new Request();
    dtcRequest.setDiagnostic(new DiagnosticCommand().setReadident(new Command()));

    return protobufTransact(port, dtcRequest, onDisconnect);
}
